$.fn.exists = function () {
  return this.length > 0;
};
// function initHeroAnimate() {
//   if ($('.js-hero-content').length) {
//     const parallaxTl = new TimelineLite();
//     const controller = new ScrollMagic.Controller();
//     var bg = $('.js-hero-content');
//     parallaxTl.from(bg, 1, {
//       y: '-50%',
//       ease: Power0.easeNone,
//     });

//     var parallaxScene = new ScrollMagic.Scene({
//       triggerElement: '.js-hero-content',
//       triggerHook: 1,
//       duration: '35%',
//     })
//       .setTween(parallaxTl)
//       .addTo(controller);
//   }
// }
function initHeroAnimate() {
  if ($('.js-hero-content').exists()) {
    const parallaxTl1 = new TimelineLite();
    const parallaxTl2 = new TimelineLite();
    const parallaxTl3 = new TimelineLite();
    const controller = new ScrollMagic.Controller();
    const h = $('.js-hero-content').outerHeight() / 2;
    parallaxTl3.to(
      '.hero__title',
      0.4,
      {
        y: '30%',
        autoAlpha: 0,

        ease: Power0.easeNone,
      },
      0
    );
    parallaxTl2.to(
      '.hero__text  ',
      0.4,
      {
        y: '30%',
        autoAlpha: 0,

        ease: Power0.easeNone,
      },
      0
    );
    parallaxTl1.to(
      '.hero__footer',
      0.4,
      {
        y: '30%',
        autoAlpha: 0,

        ease: Power0.easeNone,
      },
      0
    );

    const parallaxScene1 = new ScrollMagic.Scene({
      triggerElement: '.js-hero-content',
      triggerHook: 0.1,
      // duration: '30%',
    })
      .setTween(parallaxTl1)
      .addTo(controller);
    const parallaxScene2 = new ScrollMagic.Scene({
      triggerElement: '.js-hero-content',
      triggerHook: 0.1,
      // duration: '30%',
    })
      .setTween(parallaxTl2)
      .addTo(controller);
    const parallaxScene3 = new ScrollMagic.Scene({
      triggerElement: '.js-hero-content',
      triggerHook: 0.1,
      // duration: '30%',
    })
      .setTween(parallaxTl3)
      .addTo(controller);
  }
}
initHeroAnimate();

$('.menu-trigger').click(function () {
  $('.nav-wrap').slideToggle();
});
let windowWidth = $(window).outerWidth();
if (windowWidth < 1001) {
  $('.nav-item--dropdown').click(function (e) {
    e.preventDefault();
    // $('.nav-item__dropdown').slideUp()
    $(this).find('.nav-item__dropdown').slideToggle();
    $(this)
      .find('.nav-item__arrow i')
      .each(function () {
        $(this).toggle();
      });
  });
}
$(window).scroll(function () {
  if ($(window).scrollTop() > 40) {
    $('body').addClass('menu-fixed');
  } else {
    $('body').removeClass('menu-fixed');
  }
});

function initSlider() {
  const slider = $('.js-slider').slick({
    centerMode: true,
    variableWidth: true,
    centerPadding: '60px',
    slidesToShow: 1,
    autoplay: true,
    autoplaySpeed: 7000,
    slidesToScroll: 1,
    lazyLoad: 'ondemand',
    prevArrow:
      "<button type='button' class='slick-prev slick-arrow '><span><i class='fa fa-angle-left' aria-hidden='true'></i></span></button>",
    nextArrow:
      "<button type='button' class='slick-next slick-arrow '><span><i class='fa fa-angle-right' aria-hidden='true'></i></span></button>",
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          centerMode: false,
          centerPadding: '0px',
          slidesToShow: 1,
          variableWidth: false,
          centerMode: false,
          adaptiveHeight: true,
        },
      },
      {
        // breakpoint: 400,
        // settings: 'unslick',
        // variableWidth: false,
      },
    ],
  });
}

initSlider();
let unslick = false;
window.addEventListener('resize', function () {
  let windowWidth = $(window).outerWidth();
  if (windowWidth <= 400) {
    unslick = true;
  } else {
    if (unslick) {
      initSlider();
      unslick = false;
    }
  }
});

$('.js-video-play').click(function () {
  const video = document.getElementById('js-video');
  $(this).fadeOut();
  video.play();
});

if (windowWidth < 400) {
  $('img[data-lazy]').lazyLoadXT({
    srcAttr: 'data-lazy',
  });
}

function initParallax() {
  if ($('.js-parallax').length) {
    const parallaxTl = new TimelineLite();
    const controller = new ScrollMagic.Controller();
    var bg = $('.js-parallax-bg');
    parallaxTl.from(bg, 1, {
      y: '-20%',
      ease: Power0.easeNone,
    });

    var parallaxScene = new ScrollMagic.Scene({
      triggerElement: '.js-parallax',
      triggerHook: 1,
      duration: '215%',
    })
      .setTween(parallaxTl)
      .addTo(controller);
  }
}
initParallax();
if ($('.form-slider').length) {
  const slider = $('.form-slider');
  slider.slick({
    fade: true,
    adaptiveHeight: true,
    draggable: false,
    arrows: false,
    dots: false,
    infinite: false,
    swipe: false,
    touchMove: false,
  });
  $('.js-go-next').click(function (e) {
    e.preventDefault();
    slider.slick('slickNext');
  });
  $('.js-go-slide').click(function (e) {
    e.preventDefault();
    const index = $(this).data('slide');
    console.log(index);
    slider.slick('slickGoTo', index - 1);
  });
}
var xhr = null;

function checkRequired(form) {
  var isValid = true;
  $('.js-required', $(form)).each(function () {
    if (!$(this).hasClass('valid')) {
      isValid = false;
    }
  });
  $('.js-required', $(form)).on('blur', function () {
    if (!$(this).hasClass('valid')) {
      isValid = false;
    }
  });
  return isValid;
}
$('.phone-number').mask('(999) 999-9999', {
  placeholder: '(ххх) ххх-хххх ',
});
$('#a-contact-form').validate({
  rules: {
    'Send[name]': {
      required: true,
      minlength: 2,
    },
    'Send[email]': {
      required: true,
      email: true,
    },
    'Send[phone]': {
      required: true,
    },
    'Contact[name]': {
      required: true,
      minlength: 2,
    },
    'Contact[email]': {
      required: true,
      email: true,
    },
    'Contact[phone]': {
      required: true,
    },
    'Contact[message]': {
      required: true,
    },
    'Insurance[name]': {
      required: true,
      minlength: 2,
    },
    'Insurance[date-of-birth]': {
      required: true,
    },
    'Insurance[insured-name]': {
      required: true,
      minlength: 2,
    },
    'Insurance[insured-date-of-birth]': {
      required: true,
    },
    'Insurance[email]': {
      required: true,
      email: true,
    },
    'Insurance[phone]': {
      required: true,
    },
    'Insurance[insurance-provider]': {
      required: true,
    },
    'Insurance[insurance-id-number]': {
      required: true,
    },
  },
  messages: {
    'Send[name]': {
      required: 'Please enter your name',
      minlength: 'Your name must consist of at least 2 characters',
    },
    'Send[email]': {
      required: 'Please enter your email',
    },
    'Send[message]': {
      required: 'Please enter your phone',
    },
    'Contact[name]': {
      required: 'Please enter your name',
      minlength: 'Your name must consist of at least 2 characters',
    },
    'Contact[email]': {
      required: 'Please enter your email',
    },
    'Contact[phone]': {
      required: 'Please enter your phone',
    },
    'Contact[message]': {
      required: 'Please enter message',
    },
    'Insurance[name]': {
      required: 'Please Enter Your Name',
      minlength: 'Your name must consist of at least 2 characters',
    },
    'Insurance[date-of-birth]': {
      required: 'Please Enter Your Date of Birth',
    },
    'Insurance[insured-name]': {
      required: 'Please Enter Insured Name',
    },
    'Insurance[insured-date-of-birth]': {
      required: 'Please Enter Insured Date of Birth ',
    },
    'Insurance[email]': {
      required: 'Please Enter Your Email',
    },
    'Insurance[phone]': {
      required: 'Please Enter Your Phone Number',
    },
    'Insurance[insurance-provider]': {
      required: 'Please Enter Insurance Provider',
    },
    'Insurance[insurance-id-number]': {
      required: 'Please Enter Insurance ID Number',
    },
  },
});

$('#a-contact-form').submit(function (e) {
  e.preventDefault();
  var form = $(this);
  var button = $(this).find('button');
  var isValid = checkRequired(form);

  if (isValid === true) {
    $('.a-send_btn').removeAttr('disabled');
    if (xhr != null) xhr.abort();
    var container = $('.j-form_loader', $(form));
    xhr = $.ajax({
      type: 'POST',
      beforeSend: function () {
        button.attr('disabled', 'disabled');
        xhr = 'send';
      },
      url: form.attr('data-action'),
      data: form.serialize(),
      error: function (data) {
        $('.js-error').addClass('is-show');
        // console.log('error');
      },
      success: function (data) {
        $('.js-success').addClass('is-show');

        // container.html(data);
      },
      complete: function () {
        xhr = null;
        setTimeout(function () {
          form.trigger('reset');
          $('.js-success').removeClass('is-show');
        }, 2000);
        button.removeAttr('disabled');
      },
    });
  }
  return false;
});

function initHeaderParallax() {
  const sc = $(window).scrollTop() / 4;
  $('.js-parallax-header').css({
    transform: `translate3d(0, ${sc}px, 0)`,
  });
}
initHeaderParallax();
$(document).scroll(() => {
  initHeaderParallax();
});
// if($('#greetModal').length>0){

//     $('#greetModal').modal('show');
//     setTimeout(function(){
//         $('#greetModal').modal('hide');
//     }, 7000)
// }
const w = $(window).outerWidth();
if (w < 650) {
  $('.hero').click(function () {
    // const iframe = $('#vimeo-player')[0];
    // const player = $f(iframe);
    const player = new Vimeo.Player($('#vimeo-player'));
    // const player = new Vimeo.Player(iframe);

    player.play();
    $('.player-btn').hide();
    player.on('play', function () {
      $('.hero__bg iframe').css({ opacity: 1 });
      $('.hero__bg').css({ background: 'none' });
    });
  });
}

function initLogoSlider() {
  if ($('.js-logo-slider').length) {
    const logoSlider = '.js-logo-slider';
    $(logoSlider).slick({
      slidesToShow: 5,
      slidesToScroll: 1,
      fade: false,
      dots: false,
      arrows: false,
      autoplay: true,
      autoplaySpeed: 0,
      // centerMode: false,
      cssEase: 'linear',
      speed: 7000,
      infinite: true,
      pauseOnHover: false,
      // centerMode: false,
      // variableWidth: true,
      // centerPadding: '60px',
      // centerPadding: '0px',
      // prevArrow: "<button class='slick-prev'>&#8250;</button>",
      // nextArrow: "<button class='slick-next'>&#8249;</button>",
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            centerMode: false,
          },
        },
        {
          breakpoint: 430,
          settings: {
            slidesToShow: 1,
            centerMode: true,
          },
        },
      ],
    });
  }
}
initLogoSlider();
