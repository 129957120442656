$("#contact-card-1").on("change",function(){
	
	$("#contact-insurance").css("display","block")
	$("#contact-member").css("display","block")
	
});

$("#contact-card-0").on("change",function(){
	
	$("#contact-insurance").css("display","none")
	$("#contact-member").css("display","none")
	
});

$("#submit-check").on("click",function(){
	
if (document.getElementById('submit-check').checked)
            {
                $('#submit-insurance-btn').removeAttr('disabled')
            }
            else
            {
                $('#submit-insurance-btn').attr('disabled', true);
            }
	
});